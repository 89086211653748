import * as React from "react";

import Layout from "../components/layout/layout";
import PlatformSecurityComponent from "../components/platform/platform_security";
import Seo from "../components/seo";
import { useTranslation } from 'react-i18next';

const PlatformSecurity = () => {
  const {t} = useTranslation();
  return (
    <>
      <Seo
        title={`${t('flexibility')}`}
        description={t('with_over_30_security_features')}
      />
      <Layout>
        <PlatformSecurityComponent />
      </Layout>
    </>
  )
}

export default PlatformSecurity
