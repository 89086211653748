import React, { useState } from "react";

import * as classes from "./exam.module.scss";
import { useTranslation } from 'react-i18next';

const Exam = ({ title, Data, page }) => {
  const {t} = useTranslation();
  const [exams, setExams] = useState([...Data]);

  const [activeState, setActiveState] = useState([
    ...Array(exams.length).fill(false),
  ]);
  const [openIndex, setOpenIndex] = useState(0);
  const statehandler = (index) => {
    let array = [...Array(exams.length).fill(false)];
    array[index] = !activeState[index];
    setActiveState(array);
  };

  return (
    <div className="my-20">
      <div className="flex justify-center items-center md:justify-between  w-full mx-auto my-10">
        <img
          alt="right star "
          src="https://d2lxkizvrhu4im.cloudfront.net/icons/right_star_icon.svg"
          className="hidden md:block"
        />
        <div className="text-4xl font-bold w-4/5 md:w-2/3 text-center">
         <h1>{t(title)}</h1>
        </div>
        <img
          alt="left star"
          src="https://d2lxkizvrhu4im.cloudfront.net/icons/left_star_icon.svg"
          className="hidden md:block"
        />
      </div>

      {/* desktop view */}
      <div className={`mx-auto w-full`}>
        <div
          className={`hidden md:flex flex-row justify-between  ${
            page === "AI" ? "items-center" : "items-start"
          } `}
          style={
            page === "AI" ? { minHeight: "550px" } : { minHeight: "500px" }
          }
        >
          <div
            className={` ${page === "AI" ? "md:w-5/12" : "md:w-2/5"} lg:pr-10`}
          >
            <nav className="w-full list-none flex flex-col  h-full justify-around ">
              {exams.map((exam, index) => (
                <li
                  className={` pl-5 border-l-2 border-transparent cursor-pointer ${
                    openIndex === index
                      ? `border-l-2 ${classes.textColor} ${classes.textColorBorder}`
                      : ""
                  }`}
                  onClick={() => {
                    setOpenIndex(index);
                  }}
                >
                  <div className=" font-bold text-sm md:text-xl">
                    {t(exam.title)}
                  </div>
                  <div className=" text-gray-500 text-xs md:text-base " dangerouslySetInnerHTML={{__html: t(exam.description)}} >
                    {/* {exam.description} */}
                  </div>
                  <div className="md:hidden block w-full">
                    <img alt="logos" src={exam.image} className="w-full" />
                  </div>
                </li>
              ))}
            </nav>
          </div>
          <div
            className="hidden md:block md:w-3/5"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={
                page === "AI"
                  ? {
                      maxHeight: "550px",
                    }
                  : { maxHeight: "450px" }
              }
              // style={{
              //   width:
              //     page === "AI" && openIndex === 0
              //       ? "70%"
              //       : page === "candidate" && openIndex === 2
              //       ? "94%"
              //       : ""
              //       ? page === "security"
              //       : "70%",
              //   // ? page === "AI" && openIndex === 1
              //   // : "40%",
              //   objectFit: "contain",
              // }}
              alt="logos"
              src={exams[openIndex].image}
              className={"w-full object-contain"}
              // className={
              //   // (openIndex === 0 && page === "certification"
              //   //   ? "w-8/12"
              //   //   : "w-full",
              //   openIndex === 2
              //     ? "w-8/12"
              //     : openIndex === 3
              //     ? "w-10/12"
              //     : openIndex === 0 && page === "certification"
              //     ? "w-10/12"
              //     : "w-full"
              //     ? page === "candidate" && openIndex === 2
              //     : "w-full"
              //     ? page === "AI" && openIndex === 0
              //     : "w-6/12"
              // }
            />
          </div>
        </div>
      </div>
      {/* mobile view */}
      <div className="flex flex-row justify-between md:hidden ">
        <div className="w-full">
          <nav className="w-full list-none flex flex-col  h-full justify-around px-5">
            {exams.map((exam, index) => (
              <li
                className={`border-2 border-gray-200 my-0  p-5 cursor-pointer ${
                  index === 0 ? "rounded-t-xl" : ""
                } ${index === exams.length - 1 ? "rounded-b-xl" : ""}`}
              >
                <div
                  className=" font-bold flex flex-row justify-between items-center text-2xl text-left"
                  onClick={() => statehandler(index)}
                >
                  <div className="w-3/4"> {exam.title}</div>
                  <div
                    className={`block md:hidden ${
                      activeState[index] ? " transform rotate-180" : ""
                    }`}
                  >
                    <img
                      alt="logos"
                      src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_down_yellow_icon.svg"
                      className="my-0 h-3"
                    />
                  </div>
                </div>

                <div
                  className={`${
                    activeState[index] ? "flex flex-col" : "hidden"
                  }`}
                >
                  <div className=" text-gray-500 text-lg " dangerouslySetInnerHTML={{__html: exam.description}}>
                    {/* {exam.description} */}
                  </div>
                  <div className="md:hidden block w-full">
                    <img alt="logos" src={exam.image} className="w-full" />
                  </div>
                </div>
              </li>
            ))}
          </nav>
        </div>
        <div className="hidden md:block w-4/5">
          <img alt="logos" src="https://d2lxkizvrhu4im.cloudfront.net/images/exams.svg" className="w-full" />
        </div>
      </div>
    </div>
  );
};

export default Exam;
