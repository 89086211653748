import React from "react";
import PlatformTopBanner from "../top_banner/platform_top_banner_2";
import Subscribe2 from "../subscribe/subscribe_2";
import Exam from "../exam/exam";
import SuccessRoad from "../get_started/success_road";
import Review from "../reviews/reviews";
import Help from "../help/help";
import { useTranslation } from 'react-i18next';

const PlatformSecurity = () => {
  const {t} = useTranslation();
  const exams = [
    {
      title: "Lockdown Browser",
      description:
        `${t('the_mereos_lockdown_browser')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/securityExam1.svg",
    },
    {
      title: "Web-App ",
      description:
        `${t('the_mereos_all_in_one_assessment')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/securityExam2.svg",
    },
    {
      title: "Extension",
      description:
        `${t('the_mereos_extension_integrates')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/securityExam3.svg",
    },
  ];

  const scalabiltyData = [
    {
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/infinity_with_peoples_inside.svg",
      title: `${t('choose_an_la_carte_solution')}`,
      description:
        `${t('access_our_three_ready_to_use')}`,
    },
    {
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/ceo_standing_infront_screen_during_videoCall.svg",
      title: `${t('deploy_your_solution_quickly')}`,
      description:
        `${t('our_all_in_one_platform_is_accessible')}`,
    },
    {
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/platform_top_banner_site_structure.svg",
      title: `${t('instant_session_review')}`,
      description:
        `${t('with_mereos_artificial_intelligence')}`,
    },
  ];

  const ReviewsData = [
    {
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/sec_test_1.jpg",
      name: "Roger Koukerjinian",
      designation: "Responsable IT chez ISCPA Paris",
      reviewTitle: `${t('what_they_say_about_mereos')}`,
      review:
        "Mereos offre une solution simple pour la surveillance d'examens à distance et peut être utilisé par plusieurs centaines d’étudiants en simultané, nous en avons fait l’expérience",
    },
    {
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/sec_test_2.jpg",
      name: "Didier Sitbon",
      designation: "Directeur Général chez Progress Santé",
      reviewTitle: `${t('what_they_say_about_mereos')}`,
      review:
        "Mereos est plus qu'une solution de proctoring. C'est un outil qui a permis à Progress Santé de mieux comprendre les notes de nos étudiants et d’offrir un enseignement plus adapté et personnalisé",
    },
    {
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/sec_test_3.jpg",
      name: "Alessia Antonuzzo",
      designation: "Recruitment Officer chez Bocconi University",
      reviewTitle: `${t('what_they_say_about_mereos')}`,
      review:
        "Au début de la pandémie, la perspective d’un recrutement 100% à distance était une crainte pour l’université. C’était sans compter sur Mereos qui nous a permis de traverser cette période et avec qui nous continuons de travailler",
    },
  ];

  return (
    <>
      <div className={`conatiner font-sans`}>
        <PlatformTopBanner
          pageType={"left"}
          pageTitle={`${t('flexibility')}`}
          title={t('the_most_flexible_solutions_on_the_market')}
          description={t('mereos_offers_a_suite_of_solutions_that')}
          imageSrc={"https://d2lxkizvrhu4im.cloudfront.net/images/flexible tr1.svg"}
        />

        {/* sub Container */}
        <div className={`mx-auto w-11/12 lg:w-10/12 `}>
          <div className="my-20">
            <Exam
              page={t("security")}
              title={t('different_products_to_meet_everyones_specific_needs')}
              Data={exams}
            />
          </div>

          <div className=" md:my-20">
            <SuccessRoad
              title={t('securing_your_evaluations')}
              Data={scalabiltyData}
              page="security"
              stylePage="security"
            />
          </div>
        </div>
        <div>
          <Review Data={ReviewsData} />
        </div>
        <div className={`mx-auto hidden md:block w-11/12 md:w-2/3 lg:w-5/6 md:my-20`}>
          <Help />
        </div>
        <Subscribe2 />
      </div>
    </>
  );
};

export default PlatformSecurity;
